import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

function MoviePage() {
  const ss = useLocation().state.folderPath;
  const sm = ss.replace("/home/moj", "http://45.147.99.21/moj"); 
 const [movieInFolder, setMovieinFolder] = useState([]);
  useEffect(() => {
    axios
      .get("http://45.147.99.21:8000/mif/", {
        params: {
          path: ss,
        },
      })
      .then(function (response) {
        setMovieinFolder(response.data);
      });
  }, []);

  return (
    <div id="mf_container">
      {movieInFolder.map((e) => (
        <Link className="movie_folder1" to="/play" state={{ fileUrl: `${sm}/${e}` }}>
          {e}
        </Link>
      ))}
    </div>
  );
}

export default MoviePage;
